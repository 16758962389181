<template>
  <div>
    <van-action-sheet v-model="_show" close-on-click-overlay closeable @click-overlay="notPay">
      <div class="content">
        <div style="text-align: center">
          <span style="font-size: 18px; color: #f52556">￥</span>
          <span style="font-size: 24px; color: #f52556">{{ price }}</span>
        </div>

        <div class="paymentType">
          <!-- 金叶子支付 -->
          <!-- <div>
            <div v-for="(v, i) in payInfo.shopJoinUser" :key="i">
              <div class="paymentType-item" @click="
                paymentTypeOne({ v, i })
                ">
                <img style="width: 18px" :src="v.type == '0' || v.type == '2' || v.type == '3' ? require('../../../assets/img/initiator.png') : v.type == '1' || v.type == '4' ? require('../../../assets/img/equal.png') : require('../../../assets/img/normal.png')
                  " alt="" />
                <span class="paymentType-item-type">{{
                  v.type == '0' || v.type == '2' || v.type == '3' ? '权益人账户' : v.type == '1' || v.type == '4' ? '发起人账户' :
                  '普通账户'
                }}</span>
                <span style="display: flex; align-items: center">
                  <span style="color: #E54320">余额：{{ v.available }}金叶子&emsp;</span>
                  <van-icon v-show="type == 4 && v.available > price && one == i
                    " name="checked" color="#E54320" size="18" />
                  <van-icon v-show="type != 4 || v.available < price || one != i
                    " name="checked" color="#b7b7b7" size="18" />
                </span>
              </div>
            </div>
          </div> -->
          <!-- 现金支付 -->
          <!-- <div class="paymentType-item" @click="paymentType(1)">
            <img style="width: 18px" src="../../../assets/img/weixin.png" alt="" />
            <span class="paymentType-item-type">微信支付</span>
            <span>
              <van-icon v-show="type != 1" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 1" name="checked" color="#E54320" size="18" />
            </span>
          </div> -->
          <div class="paymentType-item border-btn" @click="paymentType(2)">
            <img style="width: 18px" src="../../../assets/img/alipay-icon.png" alt="" />
            <span class="paymentType-item-type">支付宝支付</span>
            <span>
              <van-icon v-show="type != 2" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 2" name="checked" color="#E54320" size="18" />
            </span>
          </div>
        </div>

        <div class="footer">
          <!-- <van-button v-if="payInfo.available < price && payInfo.cloud == 0" round color="#b7b7b7"
            size="large">确认付款</van-button> -->
          <van-button round color="#E54320" size="large" @click="fukuan">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- <loading :loading="loading" /> -->
  </div>
</template>

<script>
// import Loading from '@/views/flMall/actionSheet/Loading'
import { zhifu } from "@/api/yundian/pay";
import { fuli_yundian_pay } from "@/common";
import { Toast } from "vant";
export default {
  components: {
    // Loading
  },

  data() {
    return {
      type: 2, // 1 微信 2 支付宝
      loading: false,
      timer: null,
      timer1: null,
      joinId: '',
      one: null
    };
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    price: {
      required: true,
    },
    data: {
      type: Array,
    },
    payInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _show: {
      get() {
        return this.show;
      },
      set() {
        this.$parent.submitShow = false;
      },
    },
  },

  methods: {
    //关闭弹出页
    closePay() {
      this.$emit("getSend", false);
    },
    redMiPay() {
      this.$emit("redMiPay", false);
    },
    //未付款退出
    notPay() {
      this.$emit("notPay", false);
    },
    paymentType(type) {
      this.type = type;
    },
    // 选择金叶子账户支付
    /* paymentTypeOne(val) {
      // console.log(val, '<===');
      if (val.v.available < this.price) {
        Toast.fail("账户余额不足");
        return;
      } else if (val.v.available > this.price) {
        this.one = val.i
        this.type = 4;
        this.joinId = val.v.id;
      } else {
        this.type = 4;
        this.joinId = val.v.id;
      }
    }, */
    //金叶子付款
    /* paymentType1(data) {
      if (data.money < this.price) {
        Toast.fail("账户余额不足");
        return;
      } else {
        this.type = 4;
        this.one = 1;
        this.joinId = data.id;
      }
    },
    paymentType2(data) {
      if (data.money < this.price) {
        Toast.fail("账户余额不足");
        return;
      } else {
        this.type = 4;
        this.one = 2
        this.joinId = data.id;
      }
    },
    paymentType3(data) {
      if (data.money < this.price) {
        Toast.fail("账户余额不足");
        return;
      } else {
        this.type = 4;
        this.one = 3
        this.joinId = data.id;
      }
    }, */
    fukuan() {
      this.loading = true;
      const data = {
        ids: this.data,
        type: this.type,
        // joinId: this.joinId,
      };
      // console.log(data);
      // if (this.type == 1 && this.payInfo.cloud == 1) {
      if (this.type == 1) {
        // openPay(res, "1");
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let paySession = window.sessionStorage.getItem("third-session");
        if (isIOS) {
          // Toast('返回首页')
          window.webkit.messageHandlers.wechatPay.postMessage(`requesetType=12&type=1&ids=${JSON.stringify(data.ids)}&session=${paySession}`)
        }
        if (isAndroid) {
          window.android.wxpay(`requesetType=12&type=1&ids=${JSON.stringify(data.ids)}&session=${paySession}`);
        }
        this.timer = setTimeout(() => {
          this.closePay();
        }, 1500)
      } else {
        zhifu(data)
          .then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
              /* if (this.type == 1) {
                // 微信
                openPay(res, "1");
                this.timer = setTimeout(() => {
                  this.closePay();
                }, 1500)
              } else */
              // if (this.type == 2 && this.payInfo.cloud == 1) {
              if (this.type == 2) {
                // 支付宝
                fuli_yundian_pay(res.data, "2");

                this.timer1 = setTimeout(() => {
                  this.closePay();
                }, 1500)
              } else if (this.type == 4) {
                Toast("支付成功");
                this.redMiPay();
              }
            }
          })
          .catch((err) => {
            Toast("支付请求失败", err);
          });
      }
    },
  },
  destroyed() {
    // clearTimeout(this.timer)
    clearTimeout(this.timer1)
  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 15px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &-type {
        flex: 1;
        font-size: 14px;
        color: #333;
        margin-left: 10px;
      }
    }

    .border-btn {
      border-top: 1px solid #f6f6f6;
      border-bottom: 1px solid #f6f6f6;
    }
  }
}

.footer {
  .van-button--round {
    height: 40px;
  }
}
</style>
