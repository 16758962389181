<template>
  <div>
    <van-popup v-model="_paySuccessBoolean" round position="bottom" :style="{ height: '35%' }">
      <div class="container">
        <p class="container-title">支付成功</p>
        <div class="container-icon">
          <div>
            <van-icon color="#F52556" size="60" name="checked" />
          </div>
          <p style="font-size: 20px;">恭喜您，支付成功！</p>
        </div>
        <div class="container-btn">
          <van-button type="primary" color="#E54320" block round @click="enterOrderList">返回首页</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    paySuccessBoolean: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    _paySuccessBoolean: {
      get() {
        return this.paySuccessBoolean
      },
      set() {
        this.$parent.paySuccessBoolean = false
      }
    }
  },

  methods: {
    enterOrderList() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.ydEnterOrderList.postMessage('')
      }
      if (isAndroid) {
        window.android.ydEnterOrderList()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  padding: 15px 10px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-btn {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .van-button {
      width: 100%;
      height: 40px;
    }
  }
}
</style>